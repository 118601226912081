/**
 * Update remove classes from classList of list of items and add classes to active item
 * @param {NodeList} list list
 * @param {Integer} activeIndex index of item that should become active
 * @param {String} classes string of classes
 */
export const updateListState = (
    list,
    activeIndex = 0,
    classes = 'is-active'
) => {
    list.forEach((item) => {
        item.classList.remove(classes);
    });
    list[activeIndex].classList.add(classes);
};
