export function getUrlParams(url) {
    let search = {};

    url = new URL(url);
    const query = url.search.substring(1).split('&');

    for (var i = 0; i < query.length; i++) {
        var pair = query[i].split('=');
        search[pair[0]] = decodeURIComponent(pair[1]);
    }

    return search;
}

export function getUrlPathParts(url) {
    return new URL(url).pathname.split('/');
}
