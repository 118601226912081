import $ from "jquery";

export default function (data, callback) {
    $.ajax({
        url: oceanFloor.urls.ajax,
        type: 'POST',
        data: data,
        success: function (response) {
            callback(response);
        }
    });

    // const request = new Request(oceanFloor.urls.ajax, {
    //     method: 'POST',
    //     body: JSON.stringify(data),
    //     headers: new Headers()
    // });
    // fetch(request).then(res => {
    //     console.log('woot');
    //     callback(res);
    // });
}
