/**
 *
 * SHARE ON
 *
 */
export default () => {
    const shareon = document.querySelectorAll(`.shareon`);

    if (shareon.length > 0) {
        (async () => {
            const {init} = await import('shareon');
            init();
        })();
    }
}
