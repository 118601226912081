/**
 * Scroll to an element
 * @param {*} selector DOM element or css selector
 * @param {Number} additionalOffset optional offset to control scroll point. already offsets header height.
 */
const scrollTo = (selector, additionalOffset = 0) => {
    // if selector is html element then use that, else assume it is a css selector
    const element =
        typeof selector === 'object'
            ? selector
            : document.querySelector(selector);

    if (element) {
        const elementTop = element.getBoundingClientRect().top + window.scrollY;
        const offset = document.querySelector('.js-site-header').clientHeight;
        const scrollTop = elementTop - offset - additionalOffset;

        window.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }
};

export default scrollTo;
