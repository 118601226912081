import { updateListState } from '../helpers/state';

export default () => {
    const accordionGroups = document.querySelectorAll(`.js-accordions`);

    if (!accordionGroups.length) {
        return;
    }

    accordionGroups.forEach((accordionGroup) => {
        // :scope > .js-accordion`is important to make nested accordion groups work
        const accordions = accordionGroup.querySelectorAll(
            `:scope > .js-accordion`
        );

        const expand =
            'expand' in accordionGroup.dataset
                ? accordionGroup.dataset.expand
                : '';

        if (!accordions.length) {
            return;
        }

        accordions.forEach((accordion, index) => {
            const header = accordion.querySelector(`.js-accordion-header`);
            const content = accordion.querySelector(`.js-accordion-content`);

            window.addEventListener('load', () => {
                // updateAccordionHeight();

                // setTimeout(() => {
                //     updateAccordionHeight();
                // }, 500);
                accordion.classList.add(`is-loaded`);
            });

            header.addEventListener('click', () => {
                const isCurrent = accordion.classList.contains(`is-active`);

                switch (expand) {
                    case 'multi':
                    case 'multiple':
                        if (isCurrent) {
                            accordion.classList.remove(`is-active`);
                        } else {
                            accordion.classList.add(`is-active`);
                        }
                        break;

                    case 'toggle':
                        if (isCurrent) {
                            accordion.classList.remove(`is-active`);
                        } else {
                            updateListState(accordions, index);
                        }
                        break;

                    default:
                        if (!isCurrent) {
                            updateListState(accordions, index);
                        }
                        break;
                }

                setTimeout(() => {
                    ScrollTrigger.refresh();
                }, 300);

                // updateAccordionHeight();
            });

            // function updateAccordionHeight() {
                // content.style.setProperty(
                //     '--acc-height',
                //     `${content.offsetHeight}px`
                // );
            // }
        });
    });
};
