import { checkCookie, setCookie } from '../helpers/cookies.js';
import Debounce from '../helpers/debounce.js';
import Headroom from 'headroom.js';

export default () => {
    const bp = 1250;
    const header = document.querySelector('.js-site-header');
    const trigger = document.querySelector('.js-site-header-trigger');
    const nav = document.querySelector('.js-site-nav');

    /**
     *
     * Init Headroom functionality
     *
     */
    const headroom = new Headroom(header, {
        offset: setHeaderHeight(),
        onTop: () => {
            setTimeout(() => {
                setHeaderHeight();
            }, 300);
        },
        onNotTop: () => {
            setTimeout(() => {
                setHeaderHeight();
            }, 300);
        },
    });
    headroom.init();

    setHeaderHeight();
    window.addEventListener(
        'load',
        Debounce(() => setHeaderHeight())
    );
    window.addEventListener(
        'resize',
        Debounce(() => setHeaderHeight())
    );

    /**
     *
     * Mobile Navigation
     *
     */
    trigger.addEventListener('click', (e) => {
        trigger.classList.toggle('is-active');
        nav.classList.toggle('is-active');
        header.classList.toggle('is-active');
        e.stopPropagation();
    });

    const level1 = header.querySelectorAll('.menu-item-has-children');
    [...level1].map((level) => {
        level.addEventListener('click', e => toggleMenuItem(e));
    });

    function toggleMenuItem(e) {
        if (window.innerWidth < bp) {
            e.preventDefault();
            e.stopPropagation();

            const current = e.currentTarget;
            const subMenu = current.querySelector('.js-menu-drop');

            subMenu.addEventListener('click', e => {
                e.stopPropagation();
            });

            if (!current.classList.contains('is-open')) {
                // siblings
                const siblings = [...current.parentNode.children].filter(item => item !== current);
                siblings.map((sibling) =>
                    sibling.classList.remove('is-open')
                );
            }

            // self
            current.classList.toggle('is-open');
        }
    }

    /**
     *
     * Site Banner
     *
     */
    bannerLogic();
};

const setHeaderHeight = () => {
    let offset = 1;


    const headerHeight = setHeight(
        `--site-header-total-height`,
        `.js-site-header`,
        offset
    );

    setHeight(
        `--site-header-height`,
        `.js-site-header-main`,
        offset
    );

    setHeight(
        `--site-header-secondary-height`,
        `.js-site-header-secondary`,
        offset
    );

    const bannerHeight = setHeight(`--site-banner-height`, `.js-site-banner`);

    offset = headerHeight + bannerHeight;

    return offset;
};

const setHeight = (variable, element, offset = 0) => {
    // const root = document.documentElement;
    const body = document.querySelector(`body`);
    const el = document.querySelector(element);
    if (!body) return 0;
    if (!el) return 0;

    body.style.setProperty(variable, el.offsetHeight - offset + 'px');
    return el.offsetHeight;
};

/**
 *
 * Site Banner Logic
 *
 */
const bannerLogic = () => {
    const name = `site-banner`;
    const banner = document.querySelector(`.js-site-banner`);
    const closeButton = document.querySelector(`.js-site-banner-close`);

    if (!banner || !closeButton) return;

    const close = () => {
        banner.classList.remove('is-active');
    };

    // check if cookie is already set
    if (checkCookie(name)) {
        close();
    }

    closeButton.addEventListener('click', () => {
        close();
        setCookie(name, name, 2);
    });
};
