import scrollTo from "./scrollTo";

export default function (id) {
    if (id.length === 1) return;

    const module = document.querySelector(id);

    if (module) {
        scrollTo(module);
    }
}
