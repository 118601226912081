import Swiper from 'swiper';
import { EffectFade } from 'swiper/modules';
import getIndex from '../helpers/getIndex';
import scrollToModuleById from '../helpers/scrollToModuleById';

export default () => {
    const tabsModules = document.querySelectorAll('.js-tabs');

    if (tabsModules.length > 0) {
        tabsModules.forEach((module) => {
            const titles = module.querySelectorAll(`.js-tabs-title`);
            const refresh = module.querySelectorAll(`.js-tabs-refresh`);

            const swiperClass = `#${module.id} .js-tabs-content`;
            const form = module.querySelector('form');

            const swiper = new Swiper(swiperClass, {
                modules: [EffectFade],
                effect: 'fade',
                autoHeight: true,
                allowTouchMove: false,
                resizeObserver: true,
                on: {
                    init: function (swiper) {
                        const hash = window.location.hash;
                        const hashLink =
                            document.querySelectorAll('a[href^="#"]');

                        document.addEventListener('DOMContentLoaded', (e) => {
                            if (hash) {
                                e.preventDefault();
                                switchTabOnHash(hash, swiper);
                            }
                        });

                        hashLink.forEach((link) => {
                            link.addEventListener('click', (e) => {
                                const hash =
                                    e.currentTarget.getAttribute('href');
                                switchTabOnHash(hash, swiper);
                            });
                        });
                    },
                    slideChange: function (swiper) {
                        // tab changes
                        titles.forEach((title) => {
                            title.classList.remove('is-active');
                        });
                        titles[swiper.activeIndex].classList.add('is-active');

                        setTimeout(() => {
                            ScrollTrigger.refresh();
                        }, 600);
                    },
                },
            });

            setTimeout(() => {
                swiper.update();
            }, 300);

            if (form) {
                const inputs = form.querySelectorAll(`input`);
                const dropAreas = form.querySelectorAll(`.gform_drop_area`);

                form.addEventListener('click', () => swiper.update());
                inputs.forEach((input) =>
                    input.addEventListener('focus', () => swiper.update())
                );
                dropAreas.forEach((dropArea) =>
                    dropArea.addEventListener('mouseenter', () =>
                        swiper.update()
                    )
                );
            }

            /**
             * Tab & Select
             */
            titles.forEach((title) => {
                const index = getIndex(title);

                title.addEventListener('click', function () {
                    swiper.slideTo(index);
                });
            });

            refresh.forEach((r) => {
                if (r) {
                    setTimeout(() => {
                        swiper.update();
                        ScrollTrigger.refresh();
                    }, 300);
                }
                r.addEventListener('click', function () {
                    swiper.update();
                    ScrollTrigger.refresh();
                });
            });

            function switchTabOnHash(hash, swiper) {
                let isTabHash = false;
                let tabIndex = 0;
                [...titles].map((title, index) => {
                    if (`#${title.dataset.slug}` === hash) {
                        isTabHash = true;
                        tabIndex = index;
                    }
                });

                if (isTabHash) {
                    swiper.slideTo(tabIndex);
                    scrollToModuleById(`[data-slug="${hash.replace('#', '')}"]`);
                }
            }
        });
    }
};
