import wpAjax from '../helpers/wpAjax';

export default () => {
    // ONLY PROCESS FORMS ON AGENT/TEAM SITES
    const forms = document.querySelectorAll('.gform_wrapper');

    if (forms.length > 0) {
        forms.forEach((wrap) => {
            const form = wrap.querySelector('form');
            if (!form) return;

            /**
             *
             *
             * ANY FORM
             *
             *
             */

            populateFormFromUrlData(form);
            populateFormAgentFields(form);


            /**
             *
             *
             * AGENT SITE ONLY BELOW THIS POINT
             *
             *
             */
            const body = document.querySelector('body');
            const isAgentSite = body.classList.contains('site--agent');
            if (!isAgentSite) return;


            // stop gf js from interfering
            const submit = form.querySelector('button[type="submit"]');
            if (submit) {
                submit.removeAttribute('onclick', '');
                submit.removeAttribute('onkeypress', '');
            }

            form.addEventListener('submit', (e) => {
                e.stopPropagation(); // stop gf js from interfering
                e.preventDefault(); // stop gf js from interfering


                const spinner = form.querySelector(`.gform-loader`);
                if (spinner) spinner.classList.add('is-visible');

                const data = {
                    action: 'custom_gform_submission',
                    form: serializeForm(form),
                };

                wpAjax(data, (response) => {
                    const validationSummary = wrap.querySelector(`.gform_validation_errors`);

                    // reset each time
                    if (validationSummary) validationSummary.remove();
                    if (spinner) spinner.classList.remove('is-visible');

                    // process response
                    if (response.success) {
                        if (response.data.is_valid) {
                            const confirmationMessage =
                                response?.data?.confirmation_message;

                            if (confirmationMessage) {
                                createConfirmationMessage(wrap, form, confirmationMessage);
                            }
                        } else {
                            const messages = response.data.validation_messages;
                            updateValidationSummary(wrap, form, messages);
                            updateFields(form, messages);
                        }
                    } else {
                        console.log(response);
                    }
                });
            });
        });
    }
};

export const populateFormAgentFields = (form) => {
    if (!form) return;

    const agentEmailField = form.querySelector(`.populate_agent_email input`);
    if (agentEmailField && oceanFloor.agent_email) {
        agentEmailField?.setAttribute('value', oceanFloor.agent_email);
    }

    const fromAgentField = form.querySelector(`.populate_is_from_agent input`);
    if (fromAgentField) {
        fromAgentField?.setAttribute('value', oceanFloor.agent_email ? '1' : '0');
    }

    // update agentEmailField when populate_agents select is changed
    const agentsSelect = form.querySelector(`.populate_agents select`);
    if (agentsSelect) {
        agentsSelect.addEventListener('change', () => {
            agentEmailField?.setAttribute('value', agentsSelect.value);
        });
    }
}

export const populateFormFromUrlData = (form) => {
    if (!form) return;

    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.size > 0) {
        for (const param of searchParams) {
            const input = form.querySelector(`.populate_query_${param[0]} input`);

            if (input) {
                input.setAttribute('value', param[1]);
            }
        }
    }
}


/**
 * Recreate validation summary box after form submission attempt
 *
 * @param {HTMLElement} wrap form wrapper
 * @param {HTMLElement} form form tag
 * @param {Object} messages validation messages
 * @returns void
 */
const updateValidationSummary = (wrap, form, messages) => {
    if (!messages) return;

    const anchor = wrap.querySelector(`.gform_anchor`);

    if (anchor) {
        anchor.append(createValidationSummary(form, messages));
        window.scrollTo(anchor);
    }
}

/**
 * Update field validation after form submission attempt
 *
 * @param {HTMLElement} form form tag
 * @param {Object} messages validation messages
 */
const updateFields = (form, messages) => {
    const formId = form.getAttribute('data-formid');
    const fields = form.querySelectorAll(`.gform_fields > *`);

    if (fields.length > 0) {
        fields.forEach((field) => {
            const id = field.getAttribute('id')?.replace(`field_`, '');
            const fieldNum = id.replace(`${formId}_`, '');
            const message = messages[fieldNum];

            resetFieldValidation(field);

            if (message) {
                insertFieldValidation(field, id, message);
            }
        });
    }
};

/**
 * Resets field validation classes and removes validation message
 *
 * @param {HTMLElement} field form field
 */
const resetFieldValidation = (field) => {
    field.classList.remove(`gfield_error`);

    const valDom = field.querySelector(`.gfield_validation_message`);
    if (valDom) valDom.remove();
};

/**
 * Adds validation element to invalid field
 *
 * @param {HTMLElement} field form field
 * @param {String} id [FORM_ID]_[INPUT_ID] ie: 2_1
 * @param {String} message validation message
 */
const insertFieldValidation = (field, id, message) => {
    field.classList.add(`gfield_error`);

    field.append(createFieldValidationMessage(id, message));
};

/**
 * Generates field validation HTML
 *
 * @param {String} id [FORM_ID]_[INPUT_ID] ie: 2_1
 * @param {String} message validation message
 * @returns HTMLElement
 */
const createFieldValidationMessage = (id, message) => {
    const html = document.createElement('div');
    html.classList.add(
        `gfield_description`,
        `validation_message`,
        `gfield_validation_message`
    );
    html.setAttribute('id', `validation_message_${id}`);
    html.innerHTML = message;

    return html;
};

/**
 * Generates validation summary HTML
 * @param {HTMLElement} form form tag
 * @param {Object} messages validation messages
 * @returns HTMLELement
 */
const createValidationSummary = (form, messages) => {
    const formId = form.getAttribute('data-formid');
    const html = document.createElement('div');
    html.classList.add(`gform_validation_errors`);
    html.setAttribute('id', `gform_${formId}_validation_container`);
    html.setAttribute('data-js', `gform-focus-validation-error`);
    html.setAttribute('tabindex', `-1`);

    let items = ``;
    for (const [key, value] of Object.entries(messages)) {
        items += `<li><a class="gform_validation_error_link" href="#field_${formId}_${key}">${value}</a></li>`
    };

    let inner = `
        <h2 class="gform_submission_error"><span class="gform-icon gform-icon--circle-error"></span>There was a problem with your submission. Please review the fields below.</h2>
        <ol>${items}</ol>
    `;


    html.innerHTML = inner;

    return html;
};

/**
 * Replaces gf form with confirmation message on successful form submission
 * @param {HTMLElement} wrap form wrapper
 * @param {HTMLElement} form form tag
 * @param {String} message confirmation message
 */
const createConfirmationMessage = (wrap, form, message) => {
    const html = document.createElement('div');
    html.innerHTML = message;

    form.remove();
    wrap.append(html);
};

/**
 * Process FormData into formatted Object
 *
 * @param {HTMLElement} form form tag
 * @returns Object
 */
export const serializeForm = (form) => {
    const data = new FormData(form);
    let obj = {};

    for (let [key, value] of data) {
        if (value !== '') {
            if (obj[key] !== undefined) {
                if (!Array.isArray(obj[key])) {
                    obj[key] = [obj[key]];
                }
                obj[key].push(value);
            } else {
                obj[key] = value;
            }
        }
    }

    return obj;
};
