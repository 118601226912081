import { createVideoElement, buildVideoSource } from '../helpers/video';

/**
 * Blanket class to embed functionality for various media
 */
export default () => {
    const embeds = document.querySelectorAll('.js-embed');
    if (!embeds.length) return [];

    embeds.forEach((embed) => {
        createEmbed(embed);
    });

    return embeds;
};

/**
 * Create embed for various media
 * @param {HTMLElement} embed element with target class and dataset
 */
export function createEmbed(embed) {
    const data = embed.dataset;
    let src = data.src;

    if ('' === src || -1 < src.indexOf('localhost')) {
        return;
    }

    // tester links
    // let src = 'https://blufish.com/media/images/welcome/banner.mp4';
    // let src = 'https://vimeo.com/394217749';
    // let src = 'https://player.vimeo.com/video/394217749';
    // let src = 'https://youtu.be/N2JQTUhe2pg';
    // let src = 'https://blufish.com';

    const { src: videoSrc, host } = buildVideoSource(src, data);
    src = videoSrc ? videoSrc : src;

    if ('' !== src) {
        if ('self' === host) {
            const video = createVideoElement(src, data);
            embed.appendChild(video);

            // mute video on load if muted attribute (fix for video tag)
            video.muted = 'muted';
            window.onload = () => (data.muted ? (video.muted = 'muted') : null); // just in case mute doesn't get applied above
        } else {
            const iframe = createIframeElement(src);
            embed.appendChild(iframe);
        }
    }
}

/**
 * Create Iframe Element
 * @param {string} src iframe source
 * @returns Iframe HTML Element
 */
export function createIframeElement(src) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', src);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute(
        'allow',
        'enablejsapi=1, accelerometer; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture'
    );

    return iframe;
}

export function pauseVideoEmbed(embed) {
    if (!embed) return;
    const video = embed.querySelector(`video`);
    const iframe = embed.querySelector(`iframe`);

    if (video) {
        video.pause();
    }

    if (iframe) {
        iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    }
}

export function playVideoEmbed(embed) {
    if (!embed) return;
    const video = embed.querySelector(`video`);
    const iframe = embed.querySelector(`iframe`);

    if (video) {
        video.play();
        console.log('play');
        video.muted = !video.muted;
    }

    if (iframe) {
        iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }
}
