import { getUrlParams, getUrlPathParts } from './url';

/**
 *
 * @param {string} src Video source
 * @param {object} data '.js-embed' dataset
 * @returns
 */
export function buildVideoSource(src, data) {
    let id;
    const url = new URL(src);
    const search = getUrlParams(url);
    const pathParts = getUrlPathParts(url);
    const pathLast = pathParts[pathParts.length - 1];

    const host = getVideoHost(url);

    // Video URL Params
    const autoplay = data.autoplay ? `&autoplay=1` : `&autoplay=0`;
    const muted = data.muted || data.mute ? `&muted=1&mute=1` : ``; // Youtube and Vimeo have different mute attrs
    const loop = data.loop ? `&loop=1` : ``;
    const color = data.color ? `&color=${data.color}` : ``;
    const title = !data.title ? `&title=0` : ``;
    const byline = !data.byline ? `&byline=0` : ``;
    const portrait = !data.portrait ? `&portrait=0` : ``;
    const start = data.start ? `&start=${data.start}` : ``;
    const controls = data.controls ? `&controls=1` : ``;

    switch (host) {
        case 'youtube':
            id = search.v ? search.v : pathLast;
            src = `https://www.youtube-nocookie.com/embed/${id}?rel=0&showinfo=0&version=3&enablejsapi=1${autoplay}${muted}${loop}${controls}${start}${color}`;
            break;

        case 'vimeo':
            id = pathLast;
            src = `https://player.vimeo.com/video/${id}?${autoplay}${muted}${loop}${controls}${color}${title}${byline}${portrait}`;
            break;

        default:
            break;
    }

    return { src, host };
}

/**
 * Find who is hosting the video via url
 * @param {string} url video url
 * @returns video host
 */
export function getVideoHost(url) {
    url = new URL(url);
    const hostname = url.hostname;
    const href = url.href;

    let host = '';

    if (hostname.includes('youtube.com') || hostname.includes('youtu.be')) {
        host = 'youtube';
    } else if (hostname.includes('vimeo.com')) {
        host = 'vimeo';
    } else if (href.includes('.mp4')) {
        host = 'self';
    }

    return host;
}

/**
 * Create <video> element with custom config
 * @param {string} src video url
 * @param {object} data data attributes
 * @returns
 */
export function createVideoElement(src, data) {
    const video = document.createElement('video');
    // video.setAttribute('playsinline', 'playsinline');
    const preload = data.preload ? data.preload : 'none';

    // if (data.preload) video.setAttribute('preload', preload);
    if (data.autoplay) video.setAttribute('autoplay', ``);
    if (data.muted) video.setAttribute('muted', ``);
    if (data.loop) video.setAttribute('loop', ``);
    if (data.controls) video.setAttribute('controls', `1`);
    if (data.preload) video.setAttribute('preload', ``);
    if (data.poster) video.setAttribute('poster', data.poster);
    if (data.width) video.setAttribute('width', data.width);
    if (data.height) video.setAttribute('height', data.height);

    // source tag
    const source = document.createElement('source');
    source.setAttribute('src', src);
    source.setAttribute('type', 'video/mp4');
    video.appendChild(source);

    return video;
}
