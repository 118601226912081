import MicroModal from 'micromodal';
import Header from './includes/header';
import Embeds, {pauseVideoEmbed, playVideoEmbed} from './includes/embed';
import Accordions from './includes/accordions';
import Animations from './includes/animations';
import Shareon from './includes/shareon';
import Tabs from './includes/tabs';
import Forms from './includes/forms';
import scrollToModuleById from "./helpers/scrollToModuleById";

Header();
Accordions();
Animations();
Shareon();
Tabs();
Forms();

const embeds = Embeds();
const triggerAttr = 'data-micromodal-trigger';
// const closeAttr = 'data-modal-close';
const siteContent = document.querySelector('.js-site-content');
const trigger = document.querySelectorAll(`[${triggerAttr}]`);
const modal = document.querySelectorAll('.modal');
const anchorTriggers = document.querySelectorAll('[href^="#modal:"]');
const hashLink = document.querySelectorAll('a[href*="#"]');

if (hashLink.length > 0) {
    hashLink.forEach((link) => {
        link.addEventListener('click', (event) => {
            const url = event.currentTarget.getAttribute('href');
            const parts = url.split('#');
            const path = parts[0];
            const hash = `#${parts[1]}`;

            if (path === '' || path === window.location.pathname) {
                scrollToModuleById(hash);
                event.preventDefault();
            }
        });
    });
}

if (anchorTriggers.length > 0) {
    anchorTriggers.forEach((trigger) => {
        const id = trigger.getAttribute('href').replace('#modal:', '');
        trigger.setAttribute('data-micromodal-trigger', id);
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
        });
    });
}

modal.forEach((el) => {
    siteContent.appendChild(el);
});

trigger.forEach((button) => {
    button.addEventListener('click', function (e) {
        e.preventDefault();
    });
});

MicroModal.init({
    onShow: (modal) => {
        const embed = modal.querySelector('.js-embed--video');
        if (embed) {
            playVideoEmbed(embed);
        }
    },
    onClose: (modal) => {
        const embed = modal.querySelector('.js-embed--video');
        if (embed) {
            pauseVideoEmbed(embed);
        }
    },
});

const eventsContainer = document.querySelectorAll('.js-events-list-container');

if (eventsContainer.length > 0) {
    eventsContainer.forEach((container) => {
        const list = container.querySelector('.tribe-events-calendar-list');

        if (list.innerHTML.trim() === '') {
            container.style.display = 'none';
        }
    });
}
