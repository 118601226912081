/**
 * Setup a cookie
 *
 * @param {string} name Cookie Name
 * @param {string} value Cookie Value
 * @param {Number} expiresInXHours Cookie expires X hours after set
 */
export const setCookie = (name, value, expiresInXHours) => {
    const date = new Date();
    // const days = date.getTime() + expiresInXHours * 24 * 60 * 60 * 1000;
    const expires = date.getTime() + expiresInXHours * 60 * 60 * 1000;
    date.setTime(expires);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

export const getCookieValue = (name) => {
    name += '=';
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        let c = cookies[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
};

export const checkCookie = (name) => {
    return name && getCookieValue(name) !== '';
};
